module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jorge Calle - JavaScript Developer","short_name":"Jorge Calle","start_url":"/","background_color":"#F44336","theme_color":"#F44336","display":"minimal-ui","icon":"src/images/jorge_calle_150.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e89bfff0dccce0a68af6c72ba24ccec8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-161163794-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
